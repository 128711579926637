import { Injectable } from '@angular/core'
import { io, Socket } from 'socket.io-client'
import { config } from '@config'
import { AuthService } from '@app/auth/services/auth.service'
import { Logger } from '@app/general/utils/logger.util'

@Injectable({
	providedIn: 'root',
})
export class SocketsService {
	private socket?: Socket
	private readonly logger = new Logger(SocketsService.name, {
		infoColor: '#e6a3ff',
		infoBackground: '#583f62',
	})

	constructor(private authService: AuthService) {}

	connect() {
		if (this.authService.tokenIsSet()) {
			this.logger.info('Connecting to socket io...')
			this.socket = io(config.api.url, {
				path: '/api/sockets',
				auth: {
					jwt: this.authService.accessToken,
				},
			})

			this.socket.on('connect', () => {
				this.logger.info('Connected to socket io 👍')
			})

			this.socket.on('ping', () => {
				this.logger.info('Ping pong! 👀')
			})
		}
	}

	orderUpdated(func: (id: string) => void): void {
		if (this.socket) {
			this.logger.info('Trying to listen for the "order_updated" event...')
			this.socket.on('order_updated', func)
		} else {
			this.logger.warning('Socket not connected, cannot listen for order updates')
		}
	}

	taskUpdated(func: (id: string) => void): void {
		if (this.socket) {
			this.logger.info('Trying to listen for the "task_updated" event...')
			this.socket.on('task_updated', func)
		} else {
			this.logger.warning('Socket not connected, cannot listen for task updates')
		}
	}

	chatMessageCreated(func: (id: string) => void): void {
		if (this.socket) {
			const isAlreadyListening = this.socket.hasListeners('chat_message')
			if (!isAlreadyListening) {
				this.logger.info('Trying to listen for the "chat_message" event...')
				this.socket.on('chat_message', func)
			} else {
				this.logger.info('Already listening for the "chatroom" event, skipping.')
			}
		} else {
			this.logger.warning('Socket not connected, cannot listen for chat messages updates')
		}
	}

	chatroomCreated(func: (id: string) => void): void {
		if (this.socket) {
			const isAlreadyListening = this.socket.hasListeners('chatroom')
			if (!isAlreadyListening) {
				this.logger.info('Trying to listen for the "chatroom" event...')
				this.socket.on('chatroom', func)
			} else {
				this.logger.info('Already listening for the "chatroom" event, skipping.')
			}
		} else {
			this.logger.warning('Socket not connected, cannot listen for chatrooms updates')
		}
	}

	disconnect() {
		if (this.socket) {
			this.logger.info('Disconnecting from socket io...')
			this.socket.disconnect()
		}
	}
}
